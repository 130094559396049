import React from 'react';

import {Link} from "react-router-dom";

import "./style.css"

import PhotoIcon  from '../../icons/photobank-icon.png';
import PromoIcon  from '../../icons/promomaterials-icon.png';
import BusIcon  from '../../icons/business-program-icon.png';


export default function BusinessMissionAE (props) {
    return (
        <>

            <div className="main-block1">
                <div className="main-container1">
                    <div className="container-cards1">
                        <Link className="icontab1">
                            <img src={BusIcon}/>
                        </Link>
                        <Link className="label-for-icon1">
                            <p className="text-for-icon1">Программа</p>
                        </Link>
                    </div>

                    <div className="container-cards1">
                        <Link className="icontab1">
                            <img src={PromoIcon}/>
                        </Link>
                        <Link className="label-for-icon1">
                            <p className="text-for-icon1">Промоматериалы</p>
                        </Link>
                    </div>

                    <div className="container-cards1">
                        <Link className="icontab1">
                            <img src={PhotoIcon}/>
                        </Link>
                        <Link className="label-for-icon1">
                            <p className="text-for-icon1">Фотобанк</p>
                        </Link>
                    </div>
                </div>
            </div>

        </>
    );
}
