    import React, {useEffect, useState} from 'react';
import './style.css';
import {Button, Modal, Rating, Form, FormField, Input, Divider, Loader, Dimmer} from 'semantic-ui-react'
import {useDispatch, useSelector} from 'react-redux';

import LanguageModal from "../../components/LanguageModal/LanguageModal";

import UserIcon from '../../icons/user-icon.png';
import PhoneIcon from "../../icons/phone-icon.png";
import MailIcon from "../../icons/mail-icon.png";
import { ReactComponent as EditIcon} from "../../icons/edit-icon.svg";
import LangIcon from "../../icons/lang-icon.png";
import FaqIcon from "../../icons/faq-icon.png";
import HelpIcon from "../../icons/help-icon.png";
import BookmarkIcon from "../../icons/bookmark-profile-icon.png";
import QrIcon from "../../icons/qr-icon.png";
import LikeIcon from "../../icons/like-icon.png";
import LogoutIcon from "../../icons/exit-icon.png";
import { ReactComponent as CloseIcon } from "../../icons/krest-icon.svg";
import { ReactComponent as RuIcon} from "../../icons/ru-icon.svg";
import UkIcon from "../../icons/uk-icon.png";
import CompanyIcon from "../../icons/companypr-icon.png";
import ExpIcon from "../../icons/exp-icon.png";

import VkIcon from '../../icons/vk-icon.png';
import TgIcon from '../../icons/tg-icon.png';
import YtIcon from '../../icons/yt-icon.png';
import {closeMenu} from "../../components/NavBottomBar/actions";
import {AUTH_SCREEN_MAIN, dropToken, setAuthScreen} from "../../components/UserLogin/actions";
import {putTicket} from "../../components/UserLogin/actions"
import {updateForum} from "../BusinessProgram/actions";
import {getLanguage, setLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import {toastr} from "react-redux-toastr";
import {isOnline} from "../../components/WindowDimensions";

export default function Profile (props) {
    const { navigate, dispatch, token, mixpanel } = props;
    const languageDispatch = useDispatch();
    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const userData = useSelector(state => state.userLogin.userData);

    const [userPhoto, setUserPhoto] = useState('');
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userEmail, setUserEmail] = useState('');

    const [isRatingOpen, setIsRatingOpen] = useState(false);
    const [isLangOpen, setIsLangOpen] = useState(false);
    const [isCompany, setIsCompany] = useState(false);
    const [ratingValue, setRatingValue] = useState(4);
    const [messageValue, setMessageValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!token) {
            navigate('/');
            toastr.error(t('notAuthorized'));
        } else {
            setUserPhoto(userData.avatar);
            setUserName(userData.billing.first_name + ' ' + userData.billing.last_name);
            setUserPhone(userData.billing.phone);
            setUserEmail(userData.billing.email);
        }
        if (mixpanel) {
            mixpanel.track('View. Profile')
        }
    }, []);


    const createEmailTiсket = (to, subject, message, email, phone, who) => {
        setIsLoading(true);
        const ticket = {
            subject,
            message,
            email,
            phone,
            who,
            to,
        };
        dispatch(putTicket(token, ticket, languageString))
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });

    }
    const handleSubmitRating = () => {
        if (!isOnline(true, languageString)) {

        } else {
            createEmailTiсket(
                'it@asiaexpo.space',
                'Отзыв пользователя',
                messageValue + ' Оценка: ' + ratingValue,
                userEmail,
                userPhone,
                userName
            )
            setIsRatingOpen(false);
            setRatingValue();
            setMessageValue('');
            if (mixpanel) {
                mixpanel.track('Action. Rate app')
            }
        }


    }


    const handleLanguageChange = (newLanguage)=>{
        languageDispatch(setLanguage(newLanguage))
    }

    return (
        <div className="profile-view">
            <div className="profile-info">
                {userPhoto ? (
                    <img src={userPhoto} alt="Аватар" className="profile-avatar"/>
                ) : (
                    <div className="profile-icon-container">
                        <img src={UserIcon} className="profile-icon" alt="Аватар"/>

                    </div>
                )}
                <div className="profile-info-container">
                    <div className="profile-info-text profile-name">{userName}</div>
                    <div className="profile-info-text"><img src={PhoneIcon} className='profile-contact-icon'/>{userPhone}</div>
                    <div className="profile-info-text"><img src={MailIcon} className='profile-contact-icon'/>{userEmail}</div>
                    <div className={(languageString==='ru' ? "profile-edit-text-ru" : "profile-edit-text-en") + " profile-edit-text"} onClick={() => navigate('./edit')}>{t("editProfile")}<EditIcon className="profile-edit-icon"/></div>
                </div>

            </div>
            <div className="profile-nav">
                    <LanguageModal
                        triggerButton={(

                                <div className="profile-btn-container">
                                    <div className="profile-nav-icon"><img src={LangIcon}/></div>
                                    <button className="profile-btn">{t("lang")}</button>
                                </div>

                        )}
                        onLanguageChange={handleLanguageChange}
                        currentLanguage={languageString}
                    />
                <div className="profile-btn-container" onClick={() => navigate('/faq')}>
                    <div className="profile-nav-icon"><img src={FaqIcon}/></div>
                    <button className="profile-btn">{t("faq")}</button>
                </div>
                <div className="profile-btn-container" onClick={ () => navigate('/support') }>
                    <div className="profile-nav-icon"><img src={HelpIcon}/></div>
                    <button className="profile-btn">{t("techsup")}</button>
                </div>
                {isCompany && (
                    <>
                        <div className="profile-btn-container" onClick={ () => navigate('./company')}>
                            <div className="profile-nav-icon"><img src={CompanyIcon}/></div>
                            <button className="profile-btn">Данные о компании</button>
                        </div>
                        <div className="profile-btn-container" onClick={ () => navigate('./exponents')}>
                            <div className="profile-nav-icon"><img src={ExpIcon}/></div>
                            <button className="profile-btn">Добавить экспонентов</button>
                        </div>
                    </>
                )}
                <div className="profile-btn-container" onClick={ () => navigate('/bookmarks')}>
                    <div className="profile-nav-icon"><img src={BookmarkIcon}/></div>
                    <button className="profile-btn">{t("bookmarks")}</button>
                </div>
                <div className="profile-btn-container" onClick={ () => navigate('/scan') }>
                    <div className="profile-nav-icon"><img src={QrIcon}/></div>
                    <button className="profile-btn">{t("qrCode")}</button>
                </div>
                <div className="profile-btn-container">
                    <div className="profile-nav-icon" onClick={() => setIsRatingOpen(true)}><img src={LikeIcon}/></div>
                    <Modal
                        className="profile-popup-rating"
                        open={isRatingOpen}
                        trigger={
                            <Button
                                onClick={() => setIsRatingOpen(true)}
                                content={t("rateApp")}
                                className="profile-btn"
                                primary
                            />
                        }
                        onClose={() => setIsRatingOpen(false)}
                        centered
                    >
                        <Modal.Content className="profile-popup-rating-content">
                            <Form className="profile-popup-rating-form">
                                <CloseIcon className="profile-close-icon" onClick={() => setIsRatingOpen(false)}/>
                                <FormField className="profile-popup-rating-field">
                                    <Rating
                                        className="profile-popup-rating-stars"
                                        defaultRating={ratingValue}
                                        maxRating={5}
                                        onRate={(e, { rating }) => setRatingValue(rating)}
                                        clearable
                                    />
                                    <Input
                                        placeholder={t("comment")}
                                        value={messageValue}
                                        onChange={(e, {value}) => setMessageValue(value)}
                                        transparent
                                    ></Input>
                                    <Divider fitted/>

                                </FormField>
                                <Button className='profile-popup-rating-btn' onClick={handleSubmitRating}>{t("send")}</Button>
                            </Form>
                        </Modal.Content>
                    </Modal>
                    <Dimmer active={isLoading} inverted>
                        <Loader active={isLoading}>{t("sendingRate")}...</Loader>
                    </Dimmer>
                </div>
                <div className="profile-btn-container">
                    <div className="profile-nav-icon"><img src={LogoutIcon}/></div>
                    <button onClick={() => {
                        dispatch(closeMenu());
                        dispatch(dropToken());
                        navigate('/')
                        dispatch(setAuthScreen(AUTH_SCREEN_MAIN))
                    }} className="profile-btn">{t("exit")}</button>
                </div>
                <div className="profile-social-container">
                    <a href='https://vk.com/asiaexpo_ru'><img src={VkIcon} alt="ВКонтакте"/></a>
                    <a href='https://t.me/asia_expo'><img src={TgIcon} alt="Телеграм"/></a>
                    <a href='https://www.youtube.com/@asiaexpo_ru'><img src={YtIcon} alt="Ютуб"/></a>
                </div>


            </div>
        </div>


    )
}
