import React, {useEffect, useState} from 'react';
import './style_exponent_card.css';
import { ReactComponent as GeoPoint } from '../../icons/geo-point.svg';
import { ReactComponent as Vector } from '../../icons/vector.svg';
import {toastr} from "react-redux-toastr";


import 'semantic-ui-css/semantic.min.css'
import {Dimmer, Flag, Loader, Modal, ModalContent} from "semantic-ui-react";
import {useSelector} from "react-redux";

import {useParams} from "react-router";
import {getExponents, getExponentsFetching} from "../Exponents/reducer";
import {closeSearchInput, EXPONENTS_PAGE_COUNT, fetchExponents, setTitle, setURL} from "../Exponents/action";
import BookmarkComponent from "../../components/Bookmark";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from "../../icons/sad-icon.png";
import FormMeeting from "../../components/FormMeeting";

import { ReactComponent as DownloadIcon} from "../../icons/download-presentation-icon.svg";
import { ReactComponent as WatchIcon} from "../../icons/check-icon.svg";
import { ReactComponent as VectorIcon} from "../../icons/vector-icon-expo.svg";

export default function Exponents_card(props) {

    const { dispatch, userData, token, mixpanel } = props;
    const exponents = useSelector((state) => getExponents(state.exponents));
    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));

    const [open, setOpen] = useState(false)

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const { exponentId } = useParams();

    let exponentToDisplay = exponents ? exponents.find((exponent) => exponent.id === exponentId) : null;

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    // console.log(exponentToDisplay)
    useEffect(() => {
        window.scrollTo(0, 0);
        const params = {
            page: 1,
            per_page: EXPONENTS_PAGE_COUNT,
            order: "asc",
            orderby: "id",
            event: "ASIAEXPO 2024",
            id: exponentId,
            user_id: userData.id,
            lang: languageString,
        };
        dispatch(fetchExponents(token, params));

        if (mixpanel) {
            mixpanel.track('View. Exponent page')
        }

    }, [exponentId]);

    const ExponentCard = (props) => {
        const { exponent, navigate, mixpanel } = props;


        // const handlePresentationClick = () => {
        //     window.open(exponent.promo_docs[1].url, '_blank');
        // };

        const locationExponent = exponent.events && exponent.events.active ? exponent.events.active.map(elem => elem.stand_number).join(", ") : '';
        const usersExponent = exponentToDisplay.users && exponentToDisplay.users.map((elem, index) => {
            return {
                key: elem.id,
                value: {...elem},
                text: languageString === "ru" ?
                        elem.first_name + " " + elem.last_name + " - " + elem.job_title
                    :
                        elem.first_name_en + " " + elem.last_name_en + " - " + elem.job_title_en
            }});

        return (
            <div className="body_expo_card">
                <div>
                    <img src={exponent.logo} className="image_expo_card" alt="Image_expo_card"/>
                    <div className="info_expo_card">
                        <div className="text_expo_card">
                            <GeoPoint className="geo-point_expo_card" alt="Geo-point_expo_card"/>
                            <div className="location_expo_card">{locationExponent}</div>
                            <Flag name={exponent.country.toLowerCase()} className="country_Russia_exp card-expo"/>
                        </div>
                        <div className="flag_expo_card">
                            <BookmarkComponent
                                bookmark_using_for="exponents"
                                id={exponent.id}
                                {...props}
                            />
                        </div>
                        <div
                            className="name_expo_card">{languageString === "en" ? exponent.name_en : exponent.name}</div>
                        <div className="company_info_expo_card"
                             dangerouslySetInnerHTML={{__html: languageString === "en" ? exponent.content_en : exponent.content}}>
                        </div>
                        <div className='container-exponents buttons'>
                            <div className="stries_expo_card" onClick={() => navigate(`/exposition/${exponent.id}`)}>{t("lookExposition")}
                                <Vector className="vector_expo_card" alt="Vector_expo_card"/>
                            </div>
                            <Modal
                                open={isPopupOpen}
                                className='exponent-card-popup'
                                trigger={
                                    <div
                                        onClick={!token ? () => toastr.error(t('notAuthorized')) : () => setIsPopupOpen(true)}
                                        className="stries_expo_card">{t("appointMeet")}
                                        <Vector className="vector_expo_card" alt="Vector_expo_card"/>
                                    </div>
                                }
                                onClose={() => setIsPopupOpen(false)}
                                centered
                            >
                                <ModalContent>
                                    <FormMeeting
                                        company={exponent}
                                        handleClose={() => setIsPopupOpen(false)}
                                        persons={usersExponent}
                                        stand={languageString === "en" ? exponent.events.active[0].stand_number_en : exponent.events.active[0].stand_number}
                                        token={token}
                                    />
                                </ModalContent>
                            </Modal>
                            <a target='_blank' href={exponent.url ? exponent.url : `/#/exponents/exponents-card/${exponent.id}`}
                               className="stries_expo_card_link">
                                <div className="stries_expo_card">
                                    <span>{t("toWebSite")}</span>
                                    <Vector className="vector_expo_card" alt="Vector_expo_card"/>
                                </div>
                            </a>
                        </div>


                        <div className="stries_expo_card" onClick={() => setOpen(!open)}>{t("lookPresentation")}
                            {!open ? <Vector className="vector_expo_card" alt="Vector_expo_card"/> :
                                <VectorIcon className="vector_expo_card" alt="Vector_expo_card"/>}

                        </div>
                        <div className='container-watch-presents'>
                            {open ? exponent.promo_docs.map(function (docs) {
                                return (
                                    <div className="container-present">
                                        <span className='text-presentation'>{docs.title}</span>
                                        <div className="presentation-icons">
                                            <WatchIcon className="watch-icon" onClick={() => {
                                                dispatch(setTitle(docs.title))
                                                dispatch(setURL(docs.url))
                                                navigate('/exponent-presentation')
                                            }}/>
                                            <DownloadIcon className="watch-icon" onClick={async () => {
                                                const response = await fetch(docs.url);
                                                const blob = await response.blob();

                                                const link = document.createElement('a');
                                                link.href = window.URL.createObjectURL(blob);
                                                link.download = docs.title;
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                                if (mixpanel) {
                                                    mixpanel.track('Action. Download presentation of exponent')
                                                }
                                            }}/>
                                        </div>
                                    </div>

                                )
                            }) : ''}

                            {open && exponent.promo_docs.length === 0 ? (
                                <div className="container-present-not">
                                    <div className='view-presentations'>
                                        <img src={SadIcon} alt={""}/>
                                        <span className='text-bookmark'>{t("noPresentations")}</span>
                                    </div>
                                </div>) : ''}
                        </div>


                    </div>
                    <div className="red_expo_card">
                        {t("segments")}
                    </div>
                    <div className="segments_expo_card">
                            {exponent.segments && exponent.segments.map((segment, index) => (
                                <>
                                    <span
                                        key={index}
                                        className='underline_expo_card'
                                        onClick={() => navigate(`/exponents/${segment.id}`)}
                                    >
                                         <span
                                             className='dot'>•</span> {languageString === "ru" ? segment.name : segment.name_en}
                                        </span>
                                </>
                            ))}
                    </div>


                </div>
            </div>

        );
    };


    return (
        <div>
            <Dimmer className="page-loader" active={isFetching} inverted>
                <Loader active>{t("loadInfo")} ...</Loader>
            </Dimmer>
            {exponentToDisplay ? (
                <ExponentCard
                    key={exponentToDisplay.id}
                    exponent={exponentToDisplay}
                    showLoader
                    {...props}
                />
            ) : (
                <div className='view-bookmarks'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{t("notExponents")}</span>
                </div>
            )}
        </div>
    );
}
