import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Divider, Form, Icon, Input, Label, Loader, Message, Modal,} from 'semantic-ui-react';
import {useSelector} from 'react-redux';

import {isAuthVisible} from '../NavTopBar/reducer';
import {closeAuth} from '../NavTopBar/actions';
import {
    AUTH_SCREEN_AUTH_ENTER_OTP,
    AUTH_SCREEN_MAIN, AUTH_SCREEN_RESET_PWD_ENTER_DATA, AUTH_SCREEN_RESET_PWD_ENTER_OTP, AUTH_SCREEN_SIGNUP,
    authByOTP,
    authGetOTP, clearEmail, clearPhoneNumber,
    fetchToken,
    loginByIdMTS, resetPasswordAuthByOTP, resetPasswordGetOTP,
    setAuthScreen,
    setEmail, setPhone, signup
} from './actions';
import './UserLogin.css';
import './style.css'
import {getAuthFetching, getAuthMode, getData, getUserFetching, getUserLoginError} from './reducer';
import PhoneCountryMask from "../AddressCard/PhoneCountryMask";
import {ADDRESS_PHONE, ADDRESS_PHONE_COUNTRY, fetchCounties, setAddressFields} from "../AddressCard/actions";
import {Link} from "react-router-dom";
import {ReactComponent as AsiaExpoIcon} from '../../icons/asiaexpo2024-icon.svg';
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import _ from "lodash";
import translations from "../LanguageModal/translations";
import {getLanguage} from "../LanguageModal/reducer";

import {EmailSuggestions, FioSuggestions, PartySuggestions} from 'react-dadata';
import {setPopupClose} from "../../views/EditProfile/actions";
import {toastr} from "react-redux-toastr";
import config from "../../config/config";
import {DADATA_API_KEY} from "../../config/secrets";
import {value} from "lodash/seq";
import {isOnline} from "../WindowDimensions";
import {isMobile} from "react-device-detect";


export const cleanMessage = (text) => text? text.replace(/<\/?[^>]+(>|$)/g, "") : "";

export default function UserLogin (props) {

    const {userData, dispatch, mixpanel } = props;
    const showModal = useSelector((state) => isAuthVisible(state.navtopbar));
    const isFetching = useSelector((state) => getAuthFetching(state.userLogin));
    const data = useSelector((state) => getData(state.userLogin));  
    //const isFetchingAuth = useSelector((state) => getAuthFetching(state.userLogin));
    const messageLoginError= useSelector((state) => getUserLoginError(state.userLogin));
    const authMode = useSelector((state) => getAuthMode(state.userLogin));
    const isFetchingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    const defaultSettings = {
        phone: '',
        email: userData.login ?? '',
        password: '',
        emailError: false,
        passwordError: false,
        showPassword: false,
    };

    const [state, setState] = useState(defaultSettings);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [login, setLogin] = useState("")




   // console.log('UserLogin props & states Debug Mode', authMode, isFetching, phoneNumber);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    let listPhoneCountries = [];

    listPhoneCountries =
        countries.countries ?
            Object.values(countries.countries).map((element, index) => (
                {
                    key:  index,
                    text: languageString === "en" ? element.country_en : element.country_ru,
                    flag: _.lowerCase(element.iso),
                    value: element.iso,
                    className: "dropdown-item",
                    phone_mask: element.phone_mask,
                }
            )) : [] ;

    useEffect(() => {

        // по умолчанию устанавливаем главное окно
        dispatch(setAuthScreen(AUTH_SCREEN_MAIN));

        // если стран нет, то запрашиваем их
        if (!countries || !countries.countries) {
            dispatch(fetchCounties());
        }

    }, []);

    useEffect(() => {

      if (mixpanel)
          mixpanel.track('Login Dialog Open', { ...userData });

      //setState(defaultSettings);
      //setContent(null);

    }, [showModal === true]);

    function validateForm() {
        setState({ ...state, emailError: !state.email.length, passwordError: !state.password.length});
        return (state.email.length && state.password.length);
    }

    function submitForm() {
            if (validateForm())
                dispatch(fetchToken(state.email, state.password, languageString));
                //setContent(null)
    }

    function handleSetShowPassword(show) {
        setState({...state, showPassword: show});
    }

    const handleChangeField = (fieldName, value) => {
        setPhoneNumber(value);
        //dispatch(authPhone(value))
    }

    // --------------------- MTC ID ---------------------------------------------------- //
    const [stateIdMTC, setStateIdMTC] = React.useState({
        open: false,
        loading: false,
        params: {},
    });

    const closeIdMTCloseIdMTC = () => {
        setStateIdMTC({open: false, loading: false, params: {}});
    }

    const setLoadedIdMTC = () => {
        setStateIdMTC({...stateIdMTC, loading: false});
    }

    const handleLoginByIdMTS = () => {

        function makeId(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }
            return result;
        }

        const params = {
            formData: {
                client_id: 'ASIAEXPO',
                scope: 'phone',
                state: makeId(24),
                redirect_uri: encodeURIComponent('https://cms.asiaexpo.space/wp-json/app/v1/mobile-id-callback'),
                response_type: 'code',
                login_hint: '79180229059',
                prompt: 'login',
            },
            iframe: true,
            language: 'ru',
            route: window.location.hash.slice(1),
            stateLoading: stateIdMTC.loading,
            handleSetLoaded: setLoadedIdMTC,
            handleClose: closeIdMTCloseIdMTC,
        }

        // console.log('pressed login MTS ID', params);

        //setStateIdMTC({open: true, loading: true, params: params});

        dispatch(loginByIdMTS(params.formData));
    }
    // --------------------- end of MTC ID ---------------------------------------------------- //

    const ConfirmCode = (props) => {
       // const { mixpanel } = props
      //  console.log(mixpanel)
        const [timeoutRepeat, setTimeoutRepeat] = useState(authMode.data.data.otp_get_timeout);
        const [timeoutWait, setTimeoutWait] = useState(authMode.data.data.otp_wait_timeout);

        const [textColor, setTextColor] = useState("#A7AFB7");
        const [inputs, setInputs] = useState(['', '', '', '']);

        // запускаем таймер ожидания для повторной отправки кода
        useEffect(() => {
            document.getElementById('input-number0').focus();
            let time = timeoutRepeat;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutRepeat(time);
                if (time === 0)
                    clearInterval(intervalId);
            }, 1000);
            return () => clearInterval(intervalId);

        }, []);

        // запускаем таймер ожидания для воода полученного кода
        useEffect(() => {
                let time = timeoutWait;
                const intervalId = setInterval(() => {
                    time--;
                    setTimeoutWait(time);
                    if (time === 0) {
                        clearInterval(intervalId);
                        toastr.error(t("timedOut"));
                        dispatch(setAuthScreen(AUTH_SCREEN_MAIN));
                    }
                }, 1000);
                return () => clearInterval(intervalId);
        }, []);

        function handleKeyPress(e, index) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(e.key)) {
                if (e.key === 'Backspace') {
                    if (inputs[index] === '') {
                        if (index > 0) {
                            document.getElementById(`input-number${index - 1}`).focus();
                        }
                    }
                } else {
                    if (inputs.length < 1) {
                        e.preventDefault();
                    }
                }
            } else {
                e.preventDefault();
            }
        }

        const handleClick = () => {
            //setSeconds(authMode.data.otp_get_timeout);
            setTextColor('#A7AFB7');
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));
        }

        const [error, setError] = useState(false)
        const handleInput = (index, value) => {
            const newInputs = [...inputs];
            newInputs[index] = value;

            if (value && index < inputs.length - 1) {
                document.getElementById(`input-number${index + 1}`).focus();
            } else if (!value && index > 0) {
                document.getElementById(`input-number${index - 1}`).focus();
            }

            setInputs(newInputs);

            const enteredCode = newInputs.join("");
            if (enteredCode.length === 4) {
                dispatch(authByOTP(languageString, config.APP_CODE, phoneNumber, authMode.data.data.id_session, enteredCode))
                    .then(() => {

                    })
                    .catch(() => {

                    })
            }
        }

        const handleReset = () => {
            setInputs(["", "", "", ""])
            document.getElementById('input-number0').focus();
        }

        function testJump(x){
            var ml = ~~x.getAttribute('maxlength');
            if(ml && x.value.length >= ml){
                do{
                    x = x.nextSibling;
                }
                while(x && !(/text/.test(x.type)));
                if(x && /text/.test(x.type)){
                    x.focus();
                }
            }
        }
        return (
            <div className='main-block5'>
                <AsiaExpoIcon/>
                <div className='container-numbers'>
                    <span className='text-for-label'>{t("enter4symb")}</span>
                    <span className='number-phone' id='changePhone'>{phoneNumber}</span>
                </div>
                <button id='btnCode' onClick={handleClick} disabled={timeoutRepeat > 0}>
                <span className='text-again'
                      style={{color: timeoutRepeat === 0 ? 'black' : textColor}}>{timeoutRepeat === 0 ? t("resend") : t("resend") + " " + `(${timeoutRepeat}` + " " + t("sec") + ")"}</span>
                </button>
                {(error) && <div style={{color: 'red', fontFamily: 'Muller-Bold'}}>Неверный код</div>}
                <div className='container-inputs'>
                    {
                        inputs.map((value, index) => (
                            <input
                                key={index}
                                id={`input-number${index}`}
                                maxLength='1'
                                className='input-number'
                                type={isMobile ? "number" : "text"}
                                autoComplete="off"
                                onInput={e => testJump(e.target)}
                                value={value}
                                onKeyPress={(e) => handleKeyPress(e, index)}
                                onChange={(e) => handleInput(index, e.target.value)}
                            />
                        ))
                    }
                </div>
                <div onClick={() => handleReset()} style={{color: '#A7AFB7', cursor: 'pointer'}}>{t('clearInputFields')}
                </div>
            </div>
        )
    }

    const Registration = (props) => {
        const fields = useSelector((state) => getAddressFields(state.address));

        const formFields = [
            'first_name',
            'last_name',
            'email',
            'phone_country',
            'phone',
            'login',
            'password'
        ];

        const isCyrillic = (str) => {
            const cyrillicRegex = /^[А-Яа-яЁё\s]+$/;
            return cyrillicRegex.test(str);
        };

        const [errors, setErrors] = useState({});

        const [email, setEmail] = useState("");
        const [surname, setSurname] = useState("");
        const [name, setName] = useState("");
        const [company, setCompany] = useState("");
        const [phone, setPhone] = useState("")
        const [phoneCountry, setPhoneCountry] = useState("RU")

        useEffect(() => {
            mountDirectField([
                {name: 'countryPhoneCode', value: formData.phone_country},
                {name: 'phone', value: formData.phone}
            ]);
            dispatch(setPopupClose());
        }, []);


        const [formData, setFormData] = useState({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            phone_country: "RU",
            password: "",
            login: ""
        });


        const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

        const [mailValid, setMailValid] = useState(false);


        const handleFirstNameChange = (value) => {
            validate('first_name', value.value);
            setFormData({
                ...formData,
                first_name: value.value,
            });
        }

        const handleLastNameChange = (value) => {
            validate('last_name', value.value);
            setFormData({
                ...formData,
                last_name: value.value,
            });
        }

        const handleEmailChange = (value) => {
            validate('email', value.value);
            setFormData({
                ...formData,
                email: value.value,
            });
        }

        const validateFields = () => {
            formFields.forEach(field => {
                validate(field, formData[field]);
            })
        }


        const handleChange = (e) => {
            const { name, value } = e.target;
            validate(name, value);
            setFormData({
                ...formData,
                [name]: value,
            });

        }

        const handleChangePassword = (e) => {
            setFormData({
                ...formData,
                password: e.target.value,
            });
        }

        const handleChangeLogin = (value) => {
                setFormData({
                    ...formData,
                    login: value.value,
                });

        }


        //Проверка пчоты
        const isValidEmail = (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        //Обновление полей с телефонной маской
        function updateField() {
            formData.phone_country = fields[ADDRESS_PHONE_COUNTRY];
            formData.phone = fields[ADDRESS_PHONE];
            dispatch(setAddressFields(fields));
        }

        //Заполнение полей с телефонной масокй при загрузке страницы
        const mountDirectField = (data) => {
            data.forEach((element) => {
                fields[element.name] = element.value;
            });
            updateField();
        }

        //Изменение полей при редактировании маски
        const setDirectField = (name, data) => {
            if (name === 'countryPhoneCode') {
                fields['phone'] = '';
            }
            fields[name] = data;
            updateField();
        }

        const handleSubmitRegistration = () => {
            if (!formData.first_name || !formData.last_name || !formData.password || !formData.login  || !formData.phone) {
                toastr.warning(t("validFields"));
            } else {
                dispatch(signup(languageString, config.APP_CODE, formData.phone, formData.email === "" ? "" : formData.email, formData.password, formData.first_name, formData.first_name, formData.last_name, formData.login));
            }
        }

        const validate = (fieldName, value) => {
            let fieldErrors = {};
            if (fieldName === 'first_name') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validName")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }
            if (fieldName === 'last_name') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validSurname")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }

            if (fieldName === 'login') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validLogin")};
                } if (isCyrillic(value)) {
                    fieldErrors = { [fieldName]: t("rusSymb") };
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }

            if (fieldName === 'password') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validPassword")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }

            if (fieldName === 'email') {
                if (value.includes("@")) {
                    if (!isValidEmail(value)) {
                        fieldErrors = {[fieldName]: t("validMailTwo")};
                    } else {
                        fieldErrors = {[fieldName]: ''};
                        setMailValid(true)
                    }
                } else {
                    fieldErrors = {[fieldName]: ''};
                }
            }
            if (fieldName === 'phone') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validNumber")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }

            setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
        }

        return (
            <div className='main-block-registration'>
                <div className='container-text-registration'>

                    <AsiaExpoIcon/>
                    <span className='text-for-label'>{t("toReg")}</span>
                </div>

                <div className='header-inputs'>
                    <div className='registration-container'>
                        <EmailSuggestions
                            token={DADATA_API_KEY}
                            inputProps={{
                                className: 'registration-input',
                                name: 'login',
                                id: 'login',
                                placeholder: t("userLogin"),
                                required: true,
                                type: "text",
                                onChange: handleChange
                            }}
                            defaultQuery={formData.login}
                            count='4'
                            onChange={handleChangeLogin}
                        />
                    </div>
                    {errors.login && <span className='edit-error'>{errors.login}</span>}

                    <input
                        className='registration-input'
                        placeholder={t("password")}
                        autoComplete="off"
                        name="password"
                        id="password"
                        value={formData.password}
                        onChange={(e) => {
                            handleChangePassword(e)
                            handleChange(e)
                        }}
                    />
                    {errors.password && <span className='edit-error'>{errors.password}</span>}

                </div>

                <div className='line'>
                    <Divider/>
                </div>


                <div className='container-inputs-registration'>

                    <div className='container-inp-one'>
                        <div className='registration-container'>
                            <FioSuggestions
                                token={DADATA_API_KEY}
                                inputProps={{
                                    className: 'registration-input',
                                    name: 'first_name',
                                    id: 'first_name',
                                    placeholder: t("name"),
                                    required: true,
                                    onChange: handleChange
                                }}
                                defaultQuery={formData.first_name}
                                count='4'
                                filterParts={['name']}
                                filterLanguage={languageString}
                                onChange={handleFirstNameChange}
                            />
                            {errors.first_name && <span className='edit-error'>{errors.first_name}</span>}
                        </div>


                        <div className='registration-container'>
                            <FioSuggestions
                                token={DADATA_API_KEY}
                                inputProps={{
                                    className: 'registration-input',
                                    name: 'last_name',
                                    id: 'last_name',
                                    placeholder: t("surname"),
                                    required: true,
                                    onChange: handleChange
                                }}
                                defaultQuery={formData.last_name}
                                count='4'
                                filterParts={['surname']}
                                filterLanguage={languageString}
                                onChange={handleLastNameChange}
                            />
                            {errors.last_name && <span className='edit-error'>{errors.last_name}</span>}
                        </div>

                    </div>

                    <div className='container-inp-two'>
                        <div className='registration-container'>
                            <PhoneCountryMask
                                extraClass="registration-input-phone"
                                name="phone"
                                countries={listPhoneCountries}
                                country={formData.phone_country}
                                loading={isFetchingCountries}
                                phone={formData.phone}
                                phoneMask={phoneMask}
                                handleChangeField={setDirectField}
                                nameCountry={ADDRESS_PHONE_COUNTRY}
                                namePhone={ADDRESS_PHONE}
                                fluid={true}
                                required
                            />
                            {errors.phone && <span className='edit-error'>{errors.phone}</span>}
                        </div>


                        <div className='registration-container'>
                            <EmailSuggestions
                                token={DADATA_API_KEY}
                                inputProps={{
                                    className: 'registration-input',
                                    name: 'email',
                                    id: 'email',
                                    placeholder: t("mail"),
                                    required: true,
                                    onChange: handleChange
                                }}
                                defaultQuery={formData.email}
                                count='4'
                                onChange={handleEmailChange}
                            />
                            {errors.email && <span className='edit-error'>{errors.email}</span>}
                        </div>
                    </div>
                </div>
                <div className='container-button-registration'>
                    <Button className='button-confirm-registration' onClick={() => handleSubmitRegistration()}>
                        {t("registration")}
                    </Button>
                </div>
            </div>
        )
    }

    const EnterDataReset = (props) => {
        const [errors, setErrors] = useState({});
        const fields = useSelector((state) => getAddressFields(state.address));
        const [mailValid, setMailValid] = useState(false);

        // const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

        const [formData, setFormData] = useState({
            phone: "",
            email: "",
        })


        const isValidEmail = (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        const validate = (fieldName, value) => {
            let fieldErrors = {};
            if (fieldName === 'email') {
                if (value.includes("@")) {
                    if (!isValidEmail(value)) {
                        fieldErrors = { [fieldName]: t("validMailTwo")};
                    } else {
                        fieldErrors = { [fieldName]: ''};
                        setMailValid(true)
                    }
                } else {
                    fieldErrors = { [fieldName]: ''};
                }


            }
            if (fieldName === 'phone') {
                if (!value) {
                    fieldErrors = { [fieldName]: t("validNumber")};
                } else {
                    fieldErrors = { [fieldName]: ''};
                }
            }
            setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
        }
        const handleEmailChange = (value) => {
            validate('email', value.value);
            setFormData({
                ...formData,
                email: value.value,
            });
        }

        const handleChange = (e) => {
            const { name, value } = e.target;
            validate(name, value);
            setFormData({
                ...formData,
                [name]: value,
            });

        }

        //Обновление полей с телефонной маской
        // function updateField() {
        //     formData.phone_country = fields[ADDRESS_PHONE_COUNTRY];
        //     dispatch(setPhone(fields[ADDRESS_PHONE]))
        //     console.log(formData.phone)
        //     dispatch(setAddressFields(fields));
        // }

        const handleChangeField = (fieldName, value) => {
            setFormData({
                ...formData,
                phone: value,
            });

        }


        //Заполнение полей с телефонной масокй при загрузке страницы
        // const mountDirectField = (data) => {
        //     data.forEach((element) => {
        //         fields[element.name] = element.value;
        //     });
        //     updateField();
        // }
        //
        // //Изменение полей при редактировании маски
        // const setDirectField = (name, data) => {
        //     if (name === 'countryPhoneCode') {
        //         fields['phone'] = '';
        //     }
        //     fields[name] = data;
        //     updateField();
        // }

        const handleResetPassword = () => {
            dispatch(setPhone(formData.phone))
            dispatch(setEmail(formData.email))
            if (formData.phone && formData.email) {
                dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, formData.phone === "" ? "" : formData.phone, formData.email === "" ? "" : formData.email));
            } else if (formData.phone) {
                dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, formData.phone === "" ? "" : formData.phone, formData.email === "" ? "" : formData.email));
            } else if (formData.email || formData.email.includes("@")) {
                dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, formData.phone === "" ? "" : formData.phone, formData.email === "" ? "" : formData.email));
            } else if (!formData.email || !formData.phone) {
                toastr.warning(t("validTextQuestion"));
            }
        }


        return (
            <>
                <div className='main-block-reset-pass'>
                    <AsiaExpoIcon style={{marginTop: '5%', display: "flex", justifyContent: "center"}}/>

                    <span className='text-for-label'>{t("forgotPassOne")}</span>

                    <div className='input-container-reset-pass'>
                        <div className='registration-container'>
                            <EmailSuggestions
                                token={DADATA_API_KEY}
                                inputProps={{
                                    className: 'registration-input',
                                    name: 'email',
                                    id: 'email',
                                    placeholder: t("loginOrMail"),
                                    required: true,
                                    onChange: handleChange
                                }}
                                defaultQuery={formData.email}
                                count='4'
                                filterLanguage={languageString}
                                onChange={handleEmailChange}
                            />
                            {errors.email && <span className='edit-error'>{errors.email}</span>}
                        </div>


                        <div className='container-line'>
                            <div className="line-or"></div>
                            <div className="text-or">{t("or")}</div>
                            <div className="line-or"></div>
                        </div>

                        <div className='registration-container'>
                            <PhoneCountryMask
                                extraClass="registration-input-phone"
                                name="phone"
                                countries={filteredCountries}
                                country="RU"
                                loading={isFetchingCountries}
                                phone={formData.phone}
                                // phoneMask={phoneMask}
                                handleChangeField={handleChangeField}
                                nameCountry={ADDRESS_PHONE_COUNTRY}
                                namePhone={ADDRESS_PHONE}
                                fluid={true}
                                required
                            />
                            {errors.phone && <span className='edit-error'>{errors.phone}</span>}
                        </div>
                    </div>


                    <div className='container-button-registration'>
                        <Button className='button-confirm-registration' onClick={() => handleResetPassword()}>
                            {t("resetPassword")}
                        </Button>
                        <Button className='button-confirm-registration'
                                onClick={() => dispatch(setAuthScreen(AUTH_SCREEN_MAIN))}>
                            {t("back")}
                        </Button>
                    </div>


                </div>

            </>
        )
    }

    const NewPassword = (props) => {
        const [timeoutWait, setTimeoutWait] = useState(authMode.data.data.otp_wait_timeout);
        const [timeoutRepeat, setTimeoutRepeat] = useState(authMode.data.data.otp_get_timeout);

        const [textColor, setTextColor] = useState("#A7AFB7");
        const [showPassword, setShowPassword] = useState(false)

        const [formData, setFormData] = useState({
            code: '',
            password: '',
            confirmPassword: '',
        })

        // запускаем таймер ожидания для повторной отправки кода
        useEffect(() => {

            let time = timeoutRepeat;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutRepeat(time);
                if (time === 0)
                    clearInterval(intervalId);
            }, 1000);
            return () => clearInterval(intervalId);

        }, []);

        // запускаем таймер ожидания для воода полученного кода
        useEffect(() => {
            let time = timeoutWait;
            const intervalId = setInterval(() => {
                time--;
                setTimeoutWait(time);
                if (time === 0) {
                    clearInterval(intervalId);
                    toastr.error(t("timedOut"));
                    dispatch(setAuthScreen(AUTH_SCREEN_MAIN));
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }, []);

        function handleSetShowPassword() {
            setShowPassword(!showPassword)
        }

        const handleChangeCode = (e) => {
            setFormData({ ...formData, code: e.target.value })
        }

        const handleChangePassword = (e) => {
            setFormData({ ...formData, password: e.target.value })
        }

        const handleChangeConfirmPassword = (e) => {
            setFormData({ ...formData, confirmPassword: e.target.value })
        }

        const confirmPassword = () => {
            if (formData.password && formData.code.length === 8) {
                dispatch(resetPasswordAuthByOTP(languageString, config.APP_CODE, data.phone === "" ? "" : data.phone, data.email === "" ? "" : data.email, authMode.data.data.id_session, formData.code, formData.password))
                    .then(() => {

                    })
                    .catch(() => {

                    })

            }
        }

        const handleClick = () => {
            //setSeconds(authMode.data.otp_get_timeout);
            setTextColor('#A7AFB7');
            dispatch(resetPasswordGetOTP(languageString, config.APP_CODE, data.phone === "" ? "" : data.phone, data.email === "" ? "" : data.email));
        }

        return (
            <>
                <div className='main-block-reset-pass-confirm'>
                    <AsiaExpoIcon style={{marginTop: '5%', display: "flex", justifyContent: "center"}}/>

                    <span className='text-for-label'>{t("enterOtp")}</span>

                    <div className='enter-otp'>
                        <input
                            className='registration-input'
                            placeholder={t("otp")}
                            autoComplete="off"
                            value={formData.code}
                            maxLength='8'
                            onChange={(e) => handleChangeCode(e)}
                        />
                        <input
                            className='registration-input'
                            placeholder={t("newPass")}
                            value={formData.password}
                            autoComplete="off"
                            // type={showPassword ? "" +
                            //     "" : "password"}
                            onChange={(e) => handleChangePassword(e)}
                        />
                        {/*<Label*/}
                        {/*    icon={showPassword ? "eye" : "eye slash"}*/}
                        {/*    onClick={() => handleSetShowPassword()}*/}
                        {/*    className="user-login-toggle-show-password"*/}
                        {/*    id='eye-icon-id'*/}
                        {/*/>*/}
                        {/*<input*/}
                        {/*    className='registration-input'*/}
                        {/*    type={showPassword ? "text" : "password"}*/}
                        {/*    placeholder='Подтвердите пароль'*/}
                        {/*    autoComplete="off"*/}
                        {/*    value={formData.confirmPassword}*/}
                        {/*    onChange={(e) => handleChangeConfirmPassword(e)}*/}
                        {/*/>*/}
                    </div>

                    <button id='btnCode' onClick={handleClick} disabled={timeoutRepeat > 0}>
                        <span className='text-again'
                              style={{color: timeoutRepeat === 0 ? 'black' : textColor}}>{timeoutRepeat === 0 ? t("resend") : t("resend") + " " + `(${timeoutRepeat}` + " " + t("sec") + ")"}</span>
                    </button>

                    <div className='container-button-registration'>
                        <Button className='button-confirm-registration' onClick={() => confirmPassword()}>
                            {t("accept")}
                        </Button>
                    </div>


                </div>

            </>

        )
    }

    const handleSendCode = () => {
        if (!phoneNumber && navigator.onLine) {
            toastr.warning(t("validNumber"))
        } else {
            dispatch(authGetOTP(languageString, config.APP_CODE, phoneNumber));


        }
    }

    const onClose = () => {
        dispatch(closeAuth())
        dispatch(setAuthScreen('AUTH_SCREEN_MAIN'))
    }

    const filteredCountries = listPhoneCountries.filter(country => country.value === "RU");

    return (
        <Modal
            dimmer
            closeIcon
            open={showModal}
            onClose={() => onClose()}
            centered
            closeOnDimmerClick
            className="user-login"
        >
            {authMode.mode === AUTH_SCREEN_AUTH_ENTER_OTP ? <ConfirmCode props={{phoneNumber, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_SIGNUP ? <Registration props={{phoneNumber, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_RESET_PWD_ENTER_DATA ? <EnterDataReset props={{data, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_RESET_PWD_ENTER_OTP ? <NewPassword props={{data, login, ...props}}/> : ''}
            {authMode.mode === AUTH_SCREEN_MAIN ?
                <>
                    <div className='main-block3'>
                        <AsiaExpoIcon style={{marginTop: '5%'}}/>

                        <span className='text-for-label'>{t("toLogApp")}</span>

                        <PhoneCountryMask
                            handleChangeField={handleChangeField}
                            extraClass="shipping-field shipping-phone"
                            countries={filteredCountries}
                           // loading={isMobile ? "" : isFetchingCountries}
                            country="RU"
                            phone={phoneNumber}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            namePhone={ADDRESS_PHONE}
                            fluid={false}
                        />

                        <div className='container-button-confirm'>
                            <Link className="button-confirm-code"
                            >
                                <button
                                    className='text-for-link'
                                    onClick={() => handleSendCode()}
                                >{t("sendCode")}
                                </button>
                            </Link>
                        </div>
                        <div className='container-line'>
                            <div className="line-or"></div>
                            <div className="text-or">{t("or")}</div>
                            <div className="line-or"></div>
                        </div>


                    </div>
                    <Modal.Content>
                        <Modal.Description>
                        {messageLoginError !== '' ? (
                                <Message
                                    negative
                                    icon="user times"
                                    header={t('notAuthorized')}
                                    content={cleanMessage(messageLoginError)}
                                />
                            ) : ''}
                            <Form error={state.emailError || state.passwordError} onSubmit={submitForm}
                                  //loading={Boolean(isFetching)}
                                >
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        icon="user"
                                        iconPosition="left"
                                        placeholder={t("loginOrMail")}
                                        onChange={(e) => setState({...state, email: e.target.value})}
                                        error={state.emailError}
                                        //type="email"
                                        name="login"
                                        autoComplete="on"
                                        value={state.email}
                                        id='input-numbers'
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        fluid
                                        placeholder={t("password")}
                                        onChange={(e) => setState({...state, password: e.target.value})}
                                        error={state.passwordError}
                                        type={state.showPassword ? "text" : "password"}
                                        name="password"
                                        autoComplete="on"
                                        value={state.password}
                                        iconPosition="left"
                                        labelPosition="right"
                                        id="input-numbers">
                                        <Icon name="key"/>
                                        <input/>
                                        <Label
                                            icon={state.showPassword ? "eye" : "eye slash"}
                                            onClick={() => handleSetShowPassword(!state.showPassword)}
                                            className="user-login-toggle-show-password"
                                            id='eye-icon-id'
                                        />
                                    </Form.Input>
                                </Form.Field>
                                <div className="container-buttons-auth">
                                    <button
                                        disabled={state.email === '' || state.password === ''}
                                        className={state.email === '' || state.password === '' ? "login-form-submit-button-disabled" : "login-form-submit-button"}
                                        onClick={submitForm}

                                    >
                                        {t("enter")}
                                    </button>
                                    <button
                                        className="login-form-submit-button"
                                        onClick={() => navigator.onLine ? dispatch(setAuthScreen(AUTH_SCREEN_SIGNUP)) : toastr.warning(languageString === "ru" ? "Вы не в сети. Пожалуйста, подключитесь к Интернету" : 'You are offline. Please connect to the Internet')}
                                    >
                                        {t("registration")}
                                    </button>
                                    <span className='forgot-pass' onClick={() => navigator.onLine ? dispatch(setAuthScreen(AUTH_SCREEN_RESET_PWD_ENTER_DATA)) : toastr.warning(languageString === "ru" ? "Вы не в сети. Пожалуйста, подключитесь к Интернету" : 'You are offline. Please connect to the Internet')}>{t("forgotPass")}</span>
                                </div>
                                <br/>
                                {/*<div className="container-line">*/}
                                {/*    <div className="line-or"></div>*/}
                                {/*    <div className="text-or">{t("or")}</div>*/}
                                {/*    <div className="line-or"></div>*/}
                                {/*</div>*/}
                                {/*<div className='btn-mts-id'>*/}
                                {/*    <Button className='log-with-mts-id' onClick={() => handleLoginByIdMTS()}>*/}
                                {/*        войти с <MTSIcon className="mts-icon"/>id*/}
                                {/*    </Button>*/}
                                {/*    {*/}
                                {/*        stateIdMTC.open ?*/}
                                {/*        <FormIdMTC*/}
                                {/*            {...stateIdMTC.params}*/}
                                {/*            {...props}*/}
                                {/*        />*/}
                                {/*        : ''*/}
                                {/*    }*/}
                                {/*</div>*/}
                            </Form>
                        </Modal.Description>

                        <Dimmer active={isFetching} inverted>
                            <Loader active={isFetching}>{t("loadInfo")}...</Loader>
                        </Dimmer>

                    </Modal.Content>
                </> : '' }
        </Modal>


    );
}


