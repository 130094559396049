import React, {useEffect, useState} from 'react';
import './style.css'
import {Button, ButtonGroup, Image, ImageGroup} from "semantic-ui-react";
import Icon from "../../icons/cultural-program.png";
import ModalImageGallery from "../../components/ModalImageGallery";
import {useSelector} from "react-redux";
import {getLanguage} from "../../components/LanguageModal/reducer";
import translations from "../../components/LanguageModal/translations";
import SadIcon from "../../icons/sad-icon.png";

const CulturalProgam = (props) => {
    const { mixpanel } = props
    useEffect(() => {
        window.scrollTo(0, 0);
        if (mixpanel) {
            mixpanel.track('View. Cultural Program')
        }
    }, []);

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];
    const t = (key) => {
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };
    const [content, setContent] = useState(null);

    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (index, handleChangePage) => {
        setActiveButton(index);
        handleChangePage();
    }

    const [modalActive, setModalActive] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        handleChangePage1();
        setActiveButton(1);
    }, []);

    const modalImageGallery = () => (
        <ModalImageGallery
            open={modalActive}
            handleOpen={() => setModalActive(true)}
            handleClose={() => setModalActive(false)}
            images={[
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa-2-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa-2-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_3-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_3-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_4-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_4-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/gala-1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/gala-1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/gala-2-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/gala-2-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/galau-4-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/galau-4-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/galau-3-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/galau-3-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-3-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-3-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-4-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-4-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-2-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-2-scaled.jpeg",
                },

                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-2-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-2-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-3-1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-3-1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_2-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_2-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/valleball-1.jpg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/valleball-1.jpg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/valleyball-2.jpg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/valleyball-2.jpg",
                },

            ]}
            showAsGallery={false}
            index={imageIndex}
        />);

    const onImgClick = (index) => {
        setImageIndex(index);
        setModalActive(true);
    }
    const handleChangePage1 = () =>{
        setContent(
            <>
                <div className="pageText">
                    <div className={"title-text"}>{t("openCeremony")}</div>
                    <div id={"iconText"}>
                        <img src={Icon} id="icon" alt={""}/>
                        <span className={"icon-text"}>{t("eventPlace")}</span>
                    </div>
                    <div className={"main-text"}>
                        {t("openCeremonyText")}
                    </div>
                </div>
                {navigator.onLine ?
                    <div className={"pictures"}>
                        <ImageGroup className="pictureGroup">
                            <Image
                                src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa-2-scaled.jpeg"}
                                className={"picture"} onClick={() => onImgClick(0)}/>
                            <Image
                                src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_1-scaled.jpeg"}
                                className={"picture"} onClick={() => onImgClick(1)}/>
                        </ImageGroup>
                        <ImageGroup className="pictureGroup">
                            <Image
                                src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_3-scaled.jpeg"}
                                className={"picture"} onClick={() => onImgClick(2)}/>
                            <Image
                                src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/otkritie-kongressa_4-scaled.jpeg"}
                                className={"picture"} onClick={() => onImgClick(3)}/>
                        </ImageGroup>
                    </div> : <div className='view-images'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{languageString === 'ru'
                            ? 'Для просмотра изображений, пожалуйста, подключитесь к интернету'
                            : 'To view the images, please connect to the Internet'}</span>
                    </div>}
            </>
        );
    }
    const handleChangePage2 = () => {
        setContent(
            <>
                <div className="pageText">
                    <div className={"title-text"}>{t("galaDinner")}</div>
                    <div id={"iconText"}>
                        <img src={Icon} id="icon" alt={""}/>
                        <span
                            className={"icon-text"}>{t("gala23oc")}</span>
                    </div>
                    <div className={"main-text"}>
                        {t("eveningEvent")}
                        <br/><br/>{t("receptionProgram")}
                    </div>
                </div>
                {navigator.onLine ?
                    <div className={"pictures"}>
                        <ImageGroup className="pictureGroup">
                            <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/gala-1-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(4)}/>
                            <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/gala-2-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(5)}/>
                        </ImageGroup>
                        <ImageGroup className="pictureGroup">
                            <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/galau-4-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(6)}/>
                            <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/galau-3-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(7)}/>
                        </ImageGroup>
                    </div> : <div className='view-images'>
                        <img src={SadIcon} alt={""}/>
                        <span className='text-bookmark'>{languageString === 'ru'
                            ? 'Для просмотра изображений, пожалуйста, подключитесь к интернету'
                            : 'To view the images, please connect to the Internet'}</span>
                    </div>}
            </>
        )
    }
    const handleChangePage3 = () =>{
        setContent(
            <>
                <div className="pageText">
                    <div className={"title-text"}>{t("footballTour")}</div>
                    <div id={"iconText"}>
                        <img src={Icon} id="icon" alt={""}/>
                        <span
                            className={"icon-text"}>{t("football25oc")}</span>
                    </div>
                    <div className={"main-text"}>
                        {t("healthLife")}
                    </div>
                </div>
                {navigator.onLine ? <div className={"pictures"}>
                    <ImageGroup className="pictureGroup">
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-1-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(12)}/>
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-2-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(13)}/>
                    </ImageGroup>
                    <ImageGroup className="pictureGroup">
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/futbol-3-1-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(14)}/>
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_2-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(15)}/>
                    </ImageGroup>
                </div> : <div className='view-images'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{languageString === 'ru'
                        ? 'Для просмотра изображений, пожалуйста, подключитесь к интернету'
                        : 'To view the images, please connect to the Internet'}</span>
                </div>}
            </>
        )
    }
    const handleChangePage4 = () =>{
        setContent(
            <>
                <div className="pageText">
                    <div className={"title-text"}>{t("raceAERUN")}</div>
                    <div id={"iconText"}>
                        <img src={Icon} id="icon" alt={""}/>
                        <span
                            className={"icon-text"}>{t("race24oc")}</span>
                    </div>
                    <div className={"main-text"}>
                        {t("joinRace")}
                    </div>
                </div>
                {navigator.onLine ? <div className={"pictures"}>
                    <ImageGroup className="pictureGroup">
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-3-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(8)}/>
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-4-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(9)}/>
                    </ImageGroup>
                    <ImageGroup className="pictureGroup">
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-1-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(10)}/>
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/zabeg-2-scaled.jpeg"} className={"picture"} onClick={() =>onImgClick(11)}/>
                    </ImageGroup>
                </div> : <div className='view-images'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{languageString === 'ru'
                        ? 'Для просмотра изображений, пожалуйста, подключитесь к интернету'
                        : 'To view the images, please connect to the Internet'}</span>
                </div>}
            </>
        )
    }
    const handleChangePage5 = () =>{
        setContent(
            <>
                <div className="pageText">
                    <div className={"title-text"}>{t("volleyballTour")}</div>
                    <div id={"iconText"}>
                        <img src={Icon} id="icon" alt={""}/>
                        <span
                            className={"icon-text"}>{t("volleyball25oc")}</span>
                    </div>
                    <div className={"main-text"}>
                        {t("showSkills")}
                    </div>
                </div>
                {navigator.onLine ? <div className={"pictures"}>
                    <ImageGroup className="pictureGroup">
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/valleball-1.jpg"} className={"picture"} onClick={() =>onImgClick(16)}/>
                        <Image src={"https://cms.asiaexpo.space/wp-content/uploads/2024/07/valleyball-2.jpg"} className={"picture"} onClick={() =>onImgClick(17)}/>
                    </ImageGroup>
                </div> : <div className='view-images'>
                    <img src={SadIcon} alt={""}/>
                    <span className='text-bookmark'>{languageString === 'ru'
                        ? 'Для просмотра изображений, пожалуйста, подключитесь к интернету'
                        : 'To view the images, please connect to the Internet'}</span>
                </div>}
            </>
        )
    }

    return (
        <div className={"container"}>
            <div id='button-container'>
                <ButtonGroup className="btns">
                    <Button compact id="btn" className={activeButton === 1 ? 'active' : ''} onClick={() => handleButtonClick(1, handleChangePage1)}>{t("openCeremony")}</Button>
                    <Button compact id="btn" className={activeButton === 2 ? 'active' : ''} onClick={() => handleButtonClick(2, handleChangePage2)}>{t("galaDinner")}</Button>
                </ButtonGroup>
                    <ButtonGroup className="btns" id='asiaChampionShift'>
                    <Button compact id="btn" className={activeButton === 3 ? 'active' : ''} onClick={() => handleButtonClick(3, handleChangePage3)}>{t("footballTour")} ASIA CHAMPIONSHIFT</Button>
                </ButtonGroup>
                <ButtonGroup className="btns">
                    <Button compact id="btn" className={activeButton === 4 ? 'active' : ''} onClick={() => handleButtonClick(4, handleChangePage4)}>{t("raceAERUN")}</Button>
                    <Button compact id="btn" className={activeButton === 5 ? 'active' : ''} onClick={() => handleButtonClick(5, handleChangePage5)}>{t("volleyballTour")}</Button>
                </ButtonGroup>
            </div>
            <div>{content}</div>
            {modalImageGallery()}
        </div>
    )
}
export default CulturalProgam;