import React, {useEffect, useRef, useState} from 'react';
import {Stage, Layer, Image as KonvaImage, Label, Tag, Text as KonvaText, Line, Circle} from 'react-konva';
import useImage from 'use-image';
import {fetchExponents} from "../../views/Exponents/action";
import './style.css'
import {useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import {Dimmer, Image, Loader, Modal} from "semantic-ui-react";
import {getExponents, getExponentsFetching} from "../../views/Exponents/reducer";
import translations from "../LanguageModal/translations";
import { ReactComponent as Arrow } from '../../icons/arrow-icon.svg';
import {getWindowDimensions, isOnline} from "../WindowDimensions";
import {useParams} from "react-router";
import Konva from "konva";
import {isMobile} from "react-device-detect";


const scaleBy = 1.01;

function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1, p2) {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

function isTouchEnabled() {
    return ( 'ontouchstart' in window ) ||
        ( navigator.maxTouchPoints > 0 ) ||
        ( navigator.msMaxTouchPoints > 0 );
}

const Canvas = (props) => {

    const { navigate, dispatch, token, mixpanel } = props;
    const [image] = useImage('https://cms.asiaexpo.space/wp-content/uploads/2024/08/Frame-13.png');

    useEffect(() => {
        if (!isOnline(true, languageString)) {
            navigate('/')
        }
        if (mixpanel) {
            mixpanel.track('View. Exposition')
        }
    }, []);

    const { exponentId } = useParams()

    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));

    const language = useSelector(getLanguage);
    const languageString = language[0]+language[1];

    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const [highlightedShapes, setHighlightedShapes] = useState([]);
   // const [anim, setAnim] = useState(null);

    // useEffect(() => {
    //     if (exponentId) {
    //         const shapeToAnimate = shapes.find(shape => shape.id === exponentId);
    //         if (shapeToAnimate) {
    //             const period = 2000;
    //             // Убедитесь, что у вас есть доступ к элементу для анимации
    //             const blueHex = shapeToAnimate; // здесь добавьте свой элемент, который вы хотите анимировать
    //
    //             const animation = new Konva.Animation((frame) => {
    //                 const scale = Math.sin((frame.time * 2 * Math.PI) / period) + 0.001;
    //                 blueHex.scale({ x: scale, y: scale });
    //                 blueHex.getLayer().batchDraw(); // Не забывайте перерисовывать слой
    //             }, stageRef.current);
    //
    //             animation.start();
    //             setAnim(animation);
    //         }
    //     }
    //
    //     // Очистка анимации при размонтировании или новом элементе
    //     return () => {
    //         if (anim) {
    //             anim.stop();
    //             setAnim(null);
    //         }
    //     };
    // }, [exponentId, shapes]);


    useEffect(() => {
        if (exponentId) {
            const shapeToHighlight = shapes.find(shape => shape.id === exponentId);
            if (shapeToHighlight) {
                setHighlightedShapes(prev => [...prev, exponentId]);
            }
        }
    }, [exponentId]);


    // без квадратов
    // https://cms.asiaexpo.space/wp-content/uploads/2024/08/Frame-13.png

    // c
    // https://cms.asiaexpo.space/wp-content/uploads/2024/08/Frame-12.png

    /* image dimensions 1074 765 */


    const shapes = [
        {
            id: "195089",
            shape:
                {
                    x: 325,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 20.5, 0, 20.5, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C110",
                rotation: 0,
                //min_scale: 1.2,
                x: 310,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "9АГРО" : "9AGRO",
                x: 336,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195098",
            shape:
                {
                    x: 361,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19.5, 0, 19.5, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C111",
                rotation: 0,
                //min_scale: 1.2,
                x: 345.5,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: "IBS",
                x: 372,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195133",
            shape:
                {
                    x: 387,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19.5, 0, 19.5, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C112",
                rotation: 0,
                //min_scale: 1.2,
                x: 372,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "БЕЛГОРОДСКИЕ КОРМА" : "BELGOROD FEED" ,
                x: 397,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 18,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "195154",
            shape:
                {
                    x: 413,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19.5, 0, 19.5, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C113",
                rotation: 0,
                //min_scale: 1.2,
                x: 398,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ГЕОМИР" : "GEOMIR" ,
                x: 424,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195116",
            shape:
                {
                    x: 453,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18, 0, 18, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C114",
                rotation: 0,
                //min_scale: 1.2,
                x: 437,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "АГРОВЕТ" : "AGROVET" ,
                x: 462,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195151",
            shape:
                {
                    x: 471,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18.5, 0, 18.5, 11, 0, 11],
                    // stroke: "black",
                    fill: '#a62121',
                    closed: true,
                },
            text: {
                label: "C115",
                rotation: 0,
                //min_scale: 1.2,
                x: 455,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ВИТЕБСКИЕ СЕМЕНА ТРАВ" : "VITEBSK HERB SEEDS" ,
                x: 482,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 18,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195178",
            shape:
                {
                    x: 502,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C116",
                rotation: 0,
                //min_scale: 1.2,
                x: 487,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ТГУ" : "TSU" ,
                x: 511,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197611",
            shape:
                {
                    x: 528,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 16, 0, 16, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C117",
                rotation: 0,
                //min_scale: 1.2,
                x: 511,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "СОКО" : "CO-KO" ,
                x: 536,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 18,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195228",
            shape:
                {
                    x: 558.5,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C118",
                rotation: 0,
                //min_scale: 1.2,
                x: 543,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "СИВЕТРА-АГРО" : "SIVETRA-AGRO" ,
                x: 568,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "198117",
            shape:
                {
                    x: 587,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 16, 0, 16, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C119",
                rotation: 0,
                //min_scale: 1.2,
                x: 570,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ИНТЕРГЕН" : "INTERGEN" ,
                x: 595,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 18,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197576",
            shape:
                {
                    x: 618,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 16, 0, 16, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C120",
                rotation: 0,
                //min_scale: 1.2,
                x: 601,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "АГРОПЛЕМ" : "AGROPLEM" ,
                x: 626,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "196255",
            shape:
                {
                    x: 634,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 17, 0, 17, 11, 0, 11],
                    // stroke: "black",
                    fill: '#a62121',
                    closed: true,
                },
            text: {
                label: "C121",
                rotation: 0,
                //min_scale: 1.2,
                x: 617.5,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ПОЛИДОН" : "POLYDON" ,
                x: 647,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 18,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197699",
            shape:
                {
                    x: 667,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 20.5, 0, 20.5, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C122",
                rotation: 0,
                //min_scale: 1.2,
                x: 652,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "АГМ-ПАРТС" : "AGM PARTS" ,
                x: 677,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195222",
            shape:
                {
                    x: 81,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 10, 0, 10],
                   // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C103",
                rotation: 0,
                size: 6,
                x: 65,
                y: 139.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'en' ? 'SAT GROUP' : 'САТ ГРУПП',
                x: 90,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197591",
            shape:
                {
                    x: 53,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 10, 0, 10],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C102",
                rotation: 0,
                size: 6,
                x: 37,
                y: 139.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? 'КОПЫТ РЕМ СЕРВИС' : 'KRS',
                x: 62,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 20,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195095",
            shape:
                {
                    x: 28,
                    y: 150,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 12, 0, 12, 18, 0, 18 ],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C101",
                rotation: -90,
                size: 6,
                x: 31,
                y: 185,
                //min_scale: 1.2,
            },
            tag: {
                name: 'AWTECH',
                x: 34,
                y: 151,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 20,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "198111",
            shape:
                {
                    x: 121,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18, 0, 18, 10.6, 0, 10.6],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C104",
                rotation: 0,
                size: 6,
                //min_scale: 1.2,
                x: 104.5,
                y: 140,
            },
            tag: {
                name: languageString === 'ru' ? 'АПИЦЕННА' : 'APICENNA',
                x: 129,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 15,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195081",
            shape:
                {
                    x: 139,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18, 0, 18, 10.6, 0, 10.6],
                    // stroke: "black",
                    fill: '#a62121',
                    closed: true,
                },
            text: {
                label: "C105",
                rotation: 0,
                size: 6,
                //min_scale: 1.2,
                x: 122.5,
                y: 140,
            },
            tag: {
                name: 'FEEDTECH',
                x: 151,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 22,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195110",
            shape:
                {
                    x: 173,
                    y: 137.2,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 35.5, 0, 35.5, 10, 0, 10],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C106",
                rotation: 0,
                //min_scale: 1.2,
                x: 165,
                y: 140,
                size: 6,

            },
            tag: {
                name: 'ZINPRO',
                x: 187,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "196261",
            shape:
                {
                    x: 233.5,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 15.5, 0, 15.5, 11, 0, 11],
                    // stroke: "black",
                    fill: 'red',
                    closed: true,
                },
            text: {
                label: "C107",
                rotation: 0,
                //min_scale: 1.2,
                x: 216,
                y: 140,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ПРОМОЛОКО-СОФТ" : "PROMOLOKO-SOFTWARE",
                x: 237,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 20,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195157",
            shape:
                {
                    x: 249,
                    y: 137,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 15.5, 0, 15.5, 11, 0, 11],
                    // stroke: "black",
                    fill: '#a62121',
                    closed: true,
                },
            text: {
                label: "C108",
                rotation: 0,
                //min_scale: 1.2,
                x: 232,
                y: 139.9,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ЕВРОАГРОТЕК" : "EUROAGROTEK",
                x: 260,
                y: 138,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "198123",
            shape:
                {
                    x: 64,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 10.5, 0, 10.5],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A118",
                rotation: 0,
                size: 6,
                x: 47,
                y: 169.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? "РУСПЛЕНКА" : 'RUSPLENKA',
                x: 67,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195238",
            shape:
                {
                    x: 90,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18.5, 0, 18.5, 10.5, 0, 10.5],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A117",
                rotation: 0,
                size: 6,
                x: 73,
                y: 169.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? "УЛЫБИНО" : 'ULIBINO',
                x: 93,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5.3,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195107",
            shape:
                {
                    x: 114,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18.5, 0, 18.5, 10.5, 0, 10.5],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A116",
                rotation: 0,
                size: 6,
                x: 97,
                y: 169.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? "UNORMAK" : 'UNORMAK',
                x: 116,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195200",
            shape:
                {
                    x: 138.4,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18.5, 0, 18.5, 10.5, 0, 10.5],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A115",
                rotation: 0,
                size: 6,
                x: 121,
                y: 169.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? "ПОЛИПЛЕКС" : 'POLIPLEX',
                x: 143,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195122",
            shape:
                {
                    x: 163.4,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18.5, 0, 18.5, 10.5, 0, 10.5],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A114",
                rotation: 0,
                size: 6,
                x: 146,
                y: 169.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? "АГРОНИКА" : 'AGRONICA',
                x: 167,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195139",
            shape:
                {
                    x: 188.4,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18.5, 0, 18.5, 10.5, 0, 10.5],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A113",
                rotation: 0,
                size: 6,
                x: 171,
                y: 169.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? "ВЕКТОР-БЕСТ" : 'VECTOR-BEST',
                x: 192,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195244",
            shape:
                {
                    x: 213.4,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 18.5, 0, 18.5, 10.5, 0, 10.5],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A112",
                rotation: 0,
                size: 6,
                x: 196,
                y: 169.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === 'ru' ? "ФЕРМЕНТ" : 'FERMENT',
                x: 218,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 275,
                    y: 181,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 30.5, 0, 30.5, 13.8, 0, 13.8],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === "ru" ? "СЦЕНА" : "SCENE",
                rotation: 0,
                size: 6,
                x: 265,
                y: 185,
                //min_scale: 1.2,
            },
            tag: {
                name: "",
                x: 218,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 27.6,
                    y: 181,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 38, 0, 38, 170, 0, 170],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === "ru" ? "КАФЕ" : "CAFE",
                rotation: 0,
                size: 9,
                x: 22,
                y: 250,
                //min_scale: 1.2,
            },
            tag: {
                name: "",
                x: 218,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 285,
                    y: 618.2,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 55, 16, 40, 84 , -30, 83.5],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === "ru" ? "VIP\n КАФЕ" : "VIP\n CAFE",
                rotation: 0,
                size: 9,
                x: 275,
                y: 653,
                //min_scale: 1.2,
            },
            tag: {
                name: "",
                x: 218,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 732,
                    y: 214,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, -25, -30, -14, -36, 10, -7],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: 'LOUNGE\nZONE',
                rotation: 54,
                //min_scale: 1.2,
                x: 713, // -15
                y: 174,
                size: 4.3,
            },
            tag: {
                name: '',
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 764,
                    y: 221,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, -6, -17, 8, -22, 13, -5],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: 'COFFEE',
                rotation: 0,
                //min_scale: 1.2,
                x: 743, // -15
                y: 209,
                size: 3.5,
            },
            tag: {
                name: '',
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 772,
                    y: 312,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, -25, -72, 0, -80, 22, -7],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === 'ru'
                    ? 'БИБЛИОТЕКА\n' + 'УНИВЕРСИТЕТА\n' + 'СИРИУС'
                    : 'LIBRARY\n' + 'UNIVERSITY\n' + 'SIRIUS',
                rotation: 0,
                //min_scale: 1.2,
                x: 747, // -15
                y: 270,
                size: 4,
            },
            tag: {
                name: '',
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 778,
                    y: 359,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -35, 32, -35, 32, 0],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: "LOUNGE ZONE",
                rotation: 0,
                //min_scale: 1.2,
                x: 769, // -15
                y: 340,
                size: 4,
            },
            tag: {
                name: '',
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 778,
                    y: 449,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 14, -15, 14, 0],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === 'ru' ? 'НОВОСТИ' : 'NEWS',
                rotation: 0,
                //min_scale: 1.2,
                x: 760, // -15
                y: 440,
                size: languageString === 'ru' ? 2.5 : 4,
            },
            tag: {
                name: '',
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 795,
                    y: 432,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === 'ru' ? 'НОВОСТИ' : 'NEWS',
                rotation: 0,
                //min_scale: 1.2,
                x: 778, // -15
                y: 423,
                size: languageString === 'ru' ? 2.5 : 4,
            },
            tag: {
                name: '',
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 812,
                    y: 415,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === 'ru' ? 'НОВОСТИ' : 'NEWS',
                rotation: 0,
                //min_scale: 1.2,
                x: 795, // -15
                y: 406,
                size: languageString === 'ru' ? 2.5 : 4,
            },
            tag: {
                name: '',
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 452,
                    y: 662.2,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 210, -77, 242, -6.8, 15, 80],
                    // stroke: "black",
                    fill: 'green',
                    closed: true,
                },
            text: {
                label: languageString === "ru" ? "КПП" : "Checkpoint",
                rotation: -45,
                size: 9.5,
                x: 590,
                y: 660,
                //min_scale: 1.2,
            },
            tag: {
                name: "",
                x: 218,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "1",
            shape:
                {
                    x: 520,
                    y: 637.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, -0.4, 3, -1.3, 31, 72.8, 29, 73.5],
                    // stroke: "black",
                    fill: 'black',
                    closed: true,
                },
            text: {
                label: "",
                rotation: -45,
                size: 10,
                x: 590,
                y: 660,
                //min_scale: 1.2,
            },
            tag: {
                name: "",
                x: 218,
                y: 169,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "198081",
            shape:
                {
                    x: 246,
                    y: 199.6,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0,0, 20,1.6, 20, 23.5, 0,22],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A111",
                rotation: 0,
                size: 6,
                x: 230,
                y: 210.5,
                //min_scale: 1.2,
            },
            tag: {
                name: "ASIAEXPO",
                x: 250,
                y: 203,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195530",
            shape:
                {
                    x: 323,
                    y: 188,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 17, -14, 30, 2, 14, 15],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B107",
                rotation: 0,
                //min_scale: 1.2,
                x: 313,
                y: 187,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ТИМОФЕЕВ +)" : "Timofeev+ LLC)" ,
                x: 337,
                y: 180,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197689",
            shape:
                {
                    x: 356,
                    y: 167,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 22, -6, 25, 12, 5, 17],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B108",
                rotation: 0,
                //min_scale: 1.2,
                x: 344,
                y: 172,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "НОВЫЙ ВЕК АГРОТЕХНОЛОГИЙ" : "NEO-AGRISERVIS" ,
                x: 368,
                y: 170,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 3,
                //min_scale: 2,
            }
        },
        {
            id: "195212",
            shape:
                {
                    x: 393,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 12, 0, 12],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B109",
                rotation: 0,
                //min_scale: 1.2,
                x: 378,
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "РЕММАШ" : "REMMASH",
                x: 403,
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195203",
            shape:
                {
                    x: 421,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 25, 0, 25, 11.5, 0, 11.5],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B110",
                rotation: 0,
                //min_scale: 1.2,
                x: 409,
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ЯРВЕТ" : "YARVET",
                x: 436,
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 13,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197614",
            shape:
                {
                    x: 456,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 12, 0, 12],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B111",
                rotation: 0,
                //min_scale: 1.2,
                x: 441,
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ПОЛЫМЯ" : "POLYMYA",
                x: 466,
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 8,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "198255",
            shape:
                {
                    x: 485,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 12, 0, 12],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B112",
                rotation: 0,
                //min_scale: 1.2,
                x: 470,
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "AVZ" : "AVZ",
                x: 494,
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 10,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195136",
            shape:
                {
                    x: 541,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 19, 0, 19, 12, 0, 12],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B116",
                rotation: 0,
                //min_scale: 1.2,
                x: 525,
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "БИНОМ" : "BINOM",
                x: 550,
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 6,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195225",
            shape:
                {
                    x: 568,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 20, 0, 20, 12, 0, 12],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B117",
                rotation: 0,
                //min_scale: 1.2,
                x: 553, // -15
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "СИББИОНОЦ" : "SIBBIONOC",
                x: 578, // +9
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 11.5,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197695",
            shape:
                {
                    x: 622,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 20, 0, 20, 12, 0, 12],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B118",
                rotation: 0,
                //min_scale: 1.2,
                x: 607, // -15
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "БДТ-АГРО" : "BDT-AGRO",
                x: 628, // +9
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "198179",
            shape:
                {
                    x: 647,
                    y: 165,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 20, 0, 20, 12, 0, 12],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B119",
                rotation: 0,
                //min_scale: 1.2,
                x: 632, // -15
                y: 168.5,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ASIR REGION COFFEE ASSOCIATION" : "ASIR REGION COFFEE ASSOCIATION",
                x: 662, // +9
                y: 167,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 7,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 3.2,
                //min_scale: 2,
            }
        },
        {
            id: "195241",
            shape:
                {
                    x: 240,
                    y: 243,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0,-1, 20.5, 2, 20.6, 22.8, -1,21],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A110/1",
                rotation: 0,
                size: 6,
                x: 225,
                y: 250.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === "ru" ? "ФГГБУ ВНИИЗЖ" : "FGBI VNIIZH",
                x: 250,
                y: 246,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 8,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "197598",
            shape:
                {
                    x: 237,
                    y: 269,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0,0, 20.5, 2, 20.6, 19.8, -1,18.6],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A110",
                rotation: 0,
                size: 6,
                x: 221,
                y: 275.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === "ru" ? "КУБАНСКИЙ НАУЧНЫЙ ФОНД" : "KUBAN SCIENCE FOUNDATION",
                x: 250,
                y: 273,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 6,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 2.5,

                //min_scale: 2,
            }
        },
        {
            id: "195175",
            shape:
                {
                    x: 230,
                    y: 295,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0,-1, 22.5, 1.6, 20.6, 23.5, -1,22],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A109",
                rotation: 0,
                size: 6,
                x: 215,
                y: 302.5,
                //min_scale: 1.2,
            },
            tag: {
                name: "M-COMPLEX",
                x: 245,
                y: 300.9,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "198176",
            shape:
                {
                    x: 230,
                    y: 322,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0,-1, 20.5, 1, 20.6, 21.8, -1,21],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A108",
                rotation: 0,
                size: 6,
                x: 215,
                y: 330.5,
                //min_scale: 1.2,
            },
            tag: {
                name: languageString === "ru" ? "РУСВЕТ" : "RUSVET",
                x: 245,
                y: 326,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 5,
                cornerRadius: 6,
                size: 4,

                //min_scale: 2,
            }
        },
        {
            id: "195206",
            shape:
                {
                    x: 296,
                    y: 233,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [-1, 1, 15, -31, 35, -20, 20, 11],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B106",
                rotation: 0,
                //min_scale: 1.2,
                x: 287, // -15
                y: 223,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ПРОТЕКТФИД" : "PROTECT&FEED",
                x: 316, // +9
                y: 213,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195104",
            shape:
                {
                    x: 280,
                    y: 273,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [-1, 3, 10, -29, 32, -22, 20, 11],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B105",
                rotation: 0,
                //min_scale: 1.2,
                x: 270, // -15
                y: 262,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "PAK TAVUK GIDA SAN. VE TIC A.Ş" : "PAK TAVUK GIDA SAN. VE TIC A.Ş",
                x: 296, // +9
                y: 253,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195184",
            shape:
                {
                    x: 272,
                    y: 319,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 4, -33, 28, -30, 21, 5],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B104",
                rotation: 0,
                //min_scale: 1.2,
                x: 261, // -15
                y: 302,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "НИТА-ФАРМ" : "NITA FARM",
                x: 284, // +9
                y: 297,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195181",
            shape:
                {
                    x: 270,
                    y: 366,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -35, 22, -35, 22, 0],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B103",
                rotation: 0,
                //min_scale: 1.2,
                x: 256, // -15
                y: 348,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "БАШИНКОМ" : "BASHINKOM",
                x: 280, // +9
                y: 338,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195142",
            shape:
                {
                    x: 270,
                    y: 408,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -35, 22, -35, 22, 0],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B102",
                rotation: 0,
                //min_scale: 1.2,
                x: 256, // -15
                y: 388,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ВЕТПРОМ" : "VETPROM",
                x: 280, // +9
                y: 380,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195160",
            shape:
                {
                    x: 270,
                    y: 452,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -35, 22, -35, 22, 0],
                    // stroke: "black",
                    fill: 'purple',
                    closed: true,
                },
            text: {
                label: "B101",
                rotation: 0,
                //min_scale: 1.2,
                x: 256, // -15
                y: 433,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "КОРПОРАЦИЯ СКЭСС" : "SCESS CORPORATION",
                x: 280, // +9
                y: 422,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195101",
            shape:
                {
                    x: 202.5,
                    y: 393,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -11, 22, -11, 22, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A106",
                rotation: 0,
                //min_scale: 1.2,
                x: 188, // -15
                y: 385,
                size: 6,
            },
            tag: {
                name: "LALLEMAND",
                x: 209, // +9
                y: 391,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 7,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195166",
            shape:
                {
                    x: 227,
                    y: 421.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -20, 22, -20, 22, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A105",
                rotation: 0,
                //min_scale: 1.2,
                x: 212, // -15
                y: 410,
                size: 6,
            },
            tag: {
                name: languageString === "ru" ? "МЕГАМИКС" : "MEGAMIX",
                x: 243, // +9
                y: 405,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 7,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195209",
            shape:
                {
                    x: 227,
                    y: 450.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -20, 22, -20, 22, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A104",
                rotation: 0,
                //min_scale: 1.2,
                x: 212, // -15
                y: 439,
                size: 6,
            },
            tag: {
                name: languageString === "ru" ? "ГЕОДОР" : "GEODOR",
                x: 243, // +9
                y: 433,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197706",
            shape:
                {
                    x: 80,
                    y: 370,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D114",
                rotation: 0,
                //min_scale: 1.2,
                x: 63, // -15
                y: 360,
                size: 6,
            },
            tag: {
                name: "HONG KONG YUANFENG INTERNATIONAL TRADING CO., LIMITED",
                x: 88, // +9
                y: 357,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 9,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 3,
                //min_scale: 2,
            }
        },
        {
            id: "198182",
            shape:
                {
                    x: 110,
                    y: 370,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D113",
                rotation: 0,
                //min_scale: 1.2,
                x: 93, // -15
                y: 360,
                size: 6,
            },
            tag: {
                name: 'BIOSINT',
                x: 122, // +9
                y: 357,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195187",
            shape:
                {
                    x: 140,
                    y: 370,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D112",
                rotation: 0,
                //min_scale: 1.2,
                x: 123, // -15
                y: 360,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? 'АЭРОМЕХ' : "AEROMECH",
                x: 149, // +9
                y: 357,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 12,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "195196",
            shape:
                {
                    x: 188,
                    y: 370,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -20, 23, -20, 23, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A106/1",
                rotation: 0,
                //min_scale: 1.2,
                x: 174.3, // -15
                y: 357,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ПЕНЗАСПЕЦ\nАВТОМАШ" : "PENZASPETS\nAUTOMASH",
                x: 201, // +9
                y: 357,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 15,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "195144",
            shape:
                {
                    x: 219,
                    y: 374,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -20, 23, -20, 23, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A107",
                rotation: 0,
                //min_scale: 1.2,
                x: 205.5, // -15
                y: 362,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ВИК" : "VIC",
                x: 232, // +9
                y: 357,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 4,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197683",
            shape:
                {
                    x: 164,
                    y: 370,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D111",
                rotation: 0,
                //min_scale: 1.2,
                x: 147, // -15
                y: 360,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "ОБЕЗВРЕЖИВАНИЕ ОТХОДОВ" : "WASTE DISPOSAL LLC",
                x: 176, // +9
                y: 357,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 5,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 3,
                //min_scale: 2,
            }
        },
        {
            id: "197710",
            shape:
                {
                    x: 66,
                    y: 390,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D115",
                rotation: 0,
                //min_scale: 1.2,
                x: 49, // -15
                y: 380,
                size: 6,
            },
            tag: {
                name: "GASSIN PIERRE PVT LTD",
                x: 70, // +9
                y: 377,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 4,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 2.5,
                //min_scale: 2,
            }
        },
        {
            id: "195581",
            shape:
                {
                    x: 93,
                    y: 390,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D104",
                rotation: 0,
                //min_scale: 1.2,
                x: 76, // -15
                y: 380,
                size: 6,
            },
            tag: {
                name: "Biochem Pharma",
                x: 103, // +9
                y: 387,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 7,
                cornerRadius: 6,
                size: 3,
                //min_scale: 2,
            }
        },
        {
            id: "198273",
            shape:
                {
                    x: 120,
                    y: 390,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D103",
                rotation: 0,
                //min_scale: 1.2,
                x: 103, // -15
                y: 380,
                size: 6,
            },
            tag: {
                name: "AL-QAWAFEL IND. AGR. CO.",
                x: 130, // +9
                y: 380,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 2.5,
                //min_scale: 2,
            }
        },
        {
            id: "197587",
            shape:
                {
                    x: 146,
                    y: 390,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D102",
                rotation: 0,
                //min_scale: 1.2,
                x: 129, // -15
                y: 380,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "КОНЦЕПТ" : "CONCEPT",
                x: 152, // +9
                y: 387,
                rotation: 0,
                pointerDirection: "up",
                pointerHeight: 4,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3,
                //min_scale: 2,
            }
        },
        {
            id: "195130",
            shape:
                {
                    x: 172,
                    y: 390,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -15, 16, -15, 16, 0],
                    // stroke: "black",
                    fill: '#ded421',
                    closed: true,
                },
            text: {
                label: "D101",
                rotation: 0,
                //min_scale: 1.2,
                x: 155, // -15
                y: 380,
                size: 6,
            },
            tag: {
                name: languageString === 'ru' ? "АЛЬПАТРИЯ" : "ALPATRIA",
                x: 182, // +9
                y: 379,
                rotation: 0,
                pointerDirection: "down",
                pointerHeight: 3,
                pointerWidth: 3,
                cornerRadius: 6,
                size: 3.5,
                //min_scale: 2,
            }
        },
        {
            id: "197583",
            shape:
                {
                    x: 227,
                    y: 476.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -20, 22, -20, 22, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A103",
                rotation: 0,
                //min_scale: 1.2,
                x: 212, // -15
                y: 466,
                size: 6,
            },
            tag: {
                name: languageString === "ru" ? "ВОРТЕКС" : "VORTEX",
                x: 245, // +9
                y: 463,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 4.5,
                pointerWidth: 6,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
        {
            id: "197703",
            shape:
                {
                    x: 227,
                    y: 504.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -21, 22, -21, 22, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A102",
                rotation: 0,
                //min_scale: 1.2,
                x: 212, // -15
                y: 494,
                size: 6,
            },
            tag: {
                name: "SAUDI ELECTRONIC UNIVERSITY RESEARCH & STUDIES INSTITUTE",
                x: 245, // +9
                y: 489,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 4.5,
                pointerWidth: 6,
                cornerRadius: 6,
                size: 2.7,
                //min_scale: 2,
            }
        },
        {
            id: "195172",
            shape:
                {
                    x: 227,
                    y: 531.5,
                    // To define the path of the line you should use points property.
                    // If you have three points with x and y coordinates you should define points property as:
                    // [x1, y1, x2, y2, x3, y3]
                    points: [0, 0, 0, -21, 22, -21, 22, 0],
                    // stroke: "black",
                    fill: 'lightblue',
                    closed: true,
                },
            text: {
                label: "A101",
                rotation: 0,
                //min_scale: 1.2,
                x: 212, // -15
                y: 520,
                size: 6,
            },
            tag: {
                name: languageString === "ru" ? "МИРАТОРГ" : "MIRATORG",
                x: 245, // +9
                y: 515,
                rotation: 0,
                pointerDirection: "left",
                pointerHeight: 4.5,
                pointerWidth: 6,
                cornerRadius: 6,
                size: 4,
                //min_scale: 2,
            }
        },
    ];
    const min_scale_text_label = 2.2;
    const min_scale_tag_label = 2.5;

    const [scale, setScale] = useState(1);
    const [offsetX, setOffsetX] = useState( 8.5);
    const [offsetY, setOffsetY] = useState(-4.5);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);

    const [currentExponentId, setCurrentExponentId] = useState(null);
    const [currentZoomLevel, setCurrentZoomLevel] = useState(1);

    const stageRef = useRef(null);
    let lastCenter = null;
    let lastDist = 0;

/*
    useEffect(() => {
        if (image) {
            console.log('add event listener');
            image.addEventListener('load', handleLoad);
        }
        return () => image.removeEventListener('load', handleLoad);
    }, []);
*/

    useEffect(() => {
        if (image) {
            setImageWidth(image.width);
            setImageHeight(image.height);
           // console.log('useEffect update image')
        }
    }, [image]);

    const handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
      // console.log('onLoad Fired!')
    }

    function zoomStage(event) {

        event.evt.preventDefault();
        if (stageRef.current !== null) {
            const stage = stageRef.current;
            const oldScale = stage.scaleX();
            const { x: pointerX, y: pointerY } = stage.getPointerPosition();
            const mousePointTo = {
                x: (pointerX - stage.x()) / oldScale,
                y: (pointerY - stage.y()) / oldScale,
            };
            const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
            stage.scale({ x: newScale, y: newScale });
            const newPos = {
                x: pointerX - mousePointTo.x * newScale,
                y: pointerY - mousePointTo.y * newScale,
            }
            stage.position(newPos);
            stage.batchDraw();
          //  console.log('zoomStage', );

            setCurrentZoomLevel(newScale);
        //    console.log('Current zoom level:', newScale);


        }
    }

    function handleTouch(e) {

     //   console.log('handleTouchStart');

        e.evt.preventDefault();
        var touch1 = e.evt.touches[0];
        var touch2 = e.evt.touches[1];
        const stage = stageRef.current;
        if (stage !== null) {
            if (touch1 && touch2) {
                if (stage.isDragging()) {
                    stage.stopDrag();
                }

                const p1 = {
                    x: touch1.clientX,
                    y: touch1.clientY
                };
                const p2 = {
                    x: touch2.clientX,
                    y: touch2.clientY
                };

                if (!lastCenter) {
                    lastCenter = getCenter(p1, p2);
                    return;
                }
                const newCenter = getCenter(p1, p2);

                const dist = getDistance(p1, p2);

                if (!lastDist) {
                    lastDist = dist;
                }

                // local coordinates of center point
                const pointTo = {
                    x: (newCenter.x - stage.x()) / stage.scaleX(),
                    y: (newCenter.y - stage.y()) / stage.scaleX()
                };

                const scale = stage.scaleX() * (dist / lastDist);

                stage.scaleX(scale);
                stage.scaleY(scale);

                // calculate new position of the stage
                const dx = newCenter.x - lastCenter.x;
                const dy = newCenter.y - lastCenter.y;

                const newPos = {
                    x: newCenter.x - pointTo.x * scale + dx,
                    y: newCenter.y - pointTo.y * scale + dy
                };

                stage.position(newPos);
                stage.batchDraw();

                lastDist = dist;
                lastCenter = newCenter;
            }
        }
    }

    function handleTouchEnd() {
        lastCenter = null;
        lastDist = 0;
       // console.log('handleTouchEnd');
    }

    function handleTouchStart() {
        lastCenter = null;
        lastDist = 0;
       // console.log('handleTouchStart');
    }

    const [modalVisible, setModalVisible] = useState(false);

    const handleClick = (e) => {
     //   console.log("Stage clicked, XY = ", e.evt.x + ' : ' + e.evt.y, e);
    };


    const handleLineClick = (e) => {
        if (!isOnline(true, languageString)) {

        } else {
                const id = e.target.attrs.id;
                setCurrentExponentId(id);
                setModalVisible(true);
         //       console.log("Line clicked, ID = ", id);
            }


    };

    if (imageWidth === 0)
        return (
            <Dimmer active inverted>
                <Loader active>{t("loadInfo")}...</Loader>
            </Dimmer>
        );

    return (
        <>
            <Stage
                width={window.innerWidth}
                height={window.innerHeight}
                onClick={handleClick}
                draggable={!isTouchEnabled()}
                onWheel={zoomStage /*handleWheel*/}
                onTouchMove={handleTouch}
                onTouchEnd={handleTouchEnd}
                onTouchStart={handleTouchStart}

                ref={stageRef}
            >
                <Layer>

                    {image && (
                        <>
                            <KonvaImage
                                image={image}
                                width={imageWidth}
                                height={imageHeight}
                                scaleX={scale}
                                scaleY={scale}
                                x={offsetX}
                                y={offsetY}
                            />
                        </>

                    )}
                    {shapes.map((shape, index) => {

                        const isHighlighted = highlightedShapes.includes(shape.id);

                        return (
                            <>
                                <Line
                                    x={shape.shape.x}
                                    y={shape.shape.y}
                                    id={shape.id}
                                    closed={shape.shape.closed}
                                    stroke={shape.shape.stroke}
                                    fill={isHighlighted ? 'black' : shape.shape.fill}
                                    points={shape.shape.points}
                                    listening={shape.id !== "1"}
                                    onClick={handleLineClick}
                                    onTouchStart={handleLineClick}
                                />


                                {isMobile ? (
                                    <Label x={shape.tag.x} y={shape.tag.y} listening={false}>
                                        <Tag
                                            fill={shape.id === "1" ? "transparent" : "#dbdbdb"}
                                            pointerDirection={shape.tag.pointerDirection}
                                            pointerHeight={shape.tag.pointerHeight}
                                            pointerWidth={shape.tag.pointerWidth}
                                            cornerRadius={shape.tag.cornerRadius}
                                        />
                                        <KonvaText fontSize={shape.tag.size} text={shape.tag.name} fontFamily='Muller' padding={1} width={35} align="center"/>
                                    </Label>
                                ) : min_scale_tag_label <= currentZoomLevel ? (
                                    <Label x={shape.tag.x} y={shape.tag.y} listening={false}>
                                        <Tag
                                            fill={shape.id === "1" ? "transparent" : "#dbdbdb"}
                                            pointerDirection={shape.tag.pointerDirection}
                                            pointerHeight={shape.tag.pointerHeight}
                                            pointerWidth={shape.tag.pointerWidth}
                                            cornerRadius={shape.tag.cornerRadius}
                                        />
                                        <KonvaText fontSize={shape.tag.size} text={shape.tag.name} fontFamily='Muller' padding={1} width={35} align="center"/>
                                    </Label>
                                ) : ''}

                                {isMobile ? (
                                    <Label x={shape.text.x} y={shape.text.y} listening={false}>
                                        <KonvaText fontFamily="Muller" fontSize={shape.text.size} fill={isHighlighted || shape.shape.fill === "#a62121" || shape.shape.fill === "purple" || shape.id === "1" ? "white" : "black"} text={shape.text.label} width={50} align="center" rotation={shape.text.rotation}/>
                                    </Label>
                                ) : min_scale_text_label <= currentZoomLevel ? (
                                    <Label x={shape.text.x} y={shape.text.y} listening={false}>
                                        <KonvaText fontFamily="Muller" fontSize={shape.text.size} fill={isHighlighted || shape.shape.fill === "#a62121" || shape.shape.fill === "purple" || shape.id === "1" ? "white" : "black"} text={shape.text.label} width={50} align="center" rotation={shape.text.rotation}/>
                                    </Label>
                                ) : ''}


                            </>
                        )

                    })}

                    <Circle
                        x={213.6}
                        y={665}
                        id="1"
                        radius={21.5}
                        fill="green"
                        listening={false}
                    />
                    {isMobile ? (
                        <Label x={188} y={660} listening={false}>
                            <KonvaText fontFamily="Muller" fontSize={6} fill="white" text={languageString === "ru" ? "Купольный\nкинотеатр" : "Dome\nCinema"} width={50} align="center" rotation={0}/>
                        </Label>
                    ) : min_scale_text_label <= currentZoomLevel ? (
                        <Label x={188} y={660} listening={false}>
                            <KonvaText fontFamily="Muller" fontSize={6} fill="white" text={languageString === "ru" ? "Купольный\nкинотеатр" : "Dome\nCinema"} width={50} align="center" rotation={0}/>
                        </Label>
                    ) : ''}


                </Layer>

            </Stage>

            {modalVisible && currentExponentId &&
                <ExponentModal
                    exponentId={currentExponentId}
                    closeModal={() => setModalVisible(false)}
                    token={token}
                    languageString={languageString}
                    dispatch={dispatch}
                    navigate={navigate}
                />
            }

            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching}>{t("loadInfo")}...</Loader>
            </Dimmer>

        </>
    );
};

const ExponentModal = ({ exponentId, closeModal, token, languageString, dispatch, navigate }) => {
    const [exponent, setExponent] = useState(null);
    const exponents = useSelector((state) => getExponents(state.exponents));
    const isFetching = useSelector((state) => getExponentsFetching(state.exponents));
    const language = useSelector(getLanguage);

    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const readExponents = (id) => {
        const params = {
            page: 1,
            per_page: 1,
            order: 'asc',
            id: id,
            orderby: 'upper(name)',
            event: 'ASIAEXPO 2024',
            lang: languageString,
        };

        dispatch(fetchExponents(token, params, languageString));
    };

    useEffect(() => {
        const exponentData = exponents.find(exp => exp.id === exponentId);

        if (exponentData) {
            setExponent(exponentData);
        } else {
            readExponents(exponentId);
        }
    }, [exponentId, dispatch, token, languageString]);

    useEffect(() => {
        const updatedExponent = exponents.find(exp => exp.id === exponentId);
        if (updatedExponent) {
            setExponent(updatedExponent);
        }
    }, [exponents, exponentId]);

    return (
        <Modal
            open={true}
            onClose={closeModal}
            className={isFetching ? "modal-exposition-load" : "modal-exposition"}
            closeIcon
            closeOnDimmerClick
            centered
        >
            <Modal.Content className='modal-exposition-content'>

                {isFetching
                    ?     <Dimmer active={isFetching} inverted>
                                <Loader active={true}>{t("loadInfo")}...</Loader>
                            </Dimmer>
                    : (
                        <>
                            <div className='container-data-exponent'>
                                <Image className='img-modal-exponent' src={exponent?.logo}/>
                                <div className='text-modal-exponent'>
                                <span className='subject-modal-exponent'>
                                    {languageString === "ru" ? exponent?.name : exponent?.name_en}
                                </span>
                                    <span className='desc-modal-exponent'>
                                    {languageString === "ru" ? exponent?.excerpt : exponent?.excerpt_en}
                                </span>
                                </div>
                            </div>

                            <Arrow className='more-arrow'
                                   onClick={() => navigate(`/exponents/exponents-card/${exponent?.id}`)}/>
                            <span className='text-more-exp-exposition'
                                  onClick={() => navigate(`/exponents/exponents-card/${exponent?.id}`)}>
                                 {t("details")}
                            </span>
                        </>
                    )}
            </Modal.Content>
        </Modal>
    );
};

export default Canvas;